.experience-container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 50px 20px;
  }
  
  .timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-left: 40px;
  }
  
  .timeline-item {
    display: flex;
    align-items: center;
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 600px;
    margin-bottom: 30px;
    transition: opacity 0.6s ease, transform 0.6s ease;
  }
  
  /* Timeline Marker - Pulsing Animation */
  .timeline-marker {
    width: 15px;
    height: 15px;
    background-color: #61dafb;
    border-radius: 50%;
    position: absolute;
    left: -32px;
    box-shadow: 0 0 10px #61dafb;
  }
  
  /* Timeline Line - Slides in from the left */
  .timeline-line {
    position: absolute;
    left: -25px;  /* Horizontal positioning */
    width: 3px;   /* Thin vertical line */
    height: 0;    /* Start with height 0 */
    background-color: rgba(255, 255, 255, 0.3); /* Line color */
    transition: height 1s ease-in-out;  /* Smooth vertical expansion */
    z-index: 5; /* Ensure it stays behind the markers */
  }
  
/* Style for links within the experience description */
.timeline-content a {
  color: #00f7ff; /* Bright cyan color */
  text-decoration: none; /* Removes underline by default */
  font-weight: bold; /* Optional: Makes the link stand out more */
  transition: color 0.3s ease, text-decoration 0.3s ease; /* Smooth transition for hover effect */
}

/* Hover effect to make the link brighter */
.timeline-content a:hover {
  color: #ff00ff; /* Bright pink on hover */
  text-decoration: underline; /* Adds underline when hovered */
}

/* Optional: Focus effect for accessibility */
.timeline-content a:focus {
  outline: 2px solid #ff00ff; /* Adds a bright border on focus */
  outline-offset: 2px;
}
  
  .timeline-content h3 {
    font-size: 1.5rem;
    color: #61dafb;
    margin-bottom: 5px;

    text-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.5),
    4px 4px 6px rgba(0, 0, 0, 0.3),
    6px 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  .timeline-item:hover {
    transform: translateX(10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    border: 2px solid #61dafb; /* Add a colorful border */
  }
  

  .role {
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;

    text-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.5),
    4px 4px 6px rgba(0, 0, 0, 0.3),
    6px 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  .role span {
    font-size: 1rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);

    text-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.5),
    4px 4px 6px rgba(0, 0, 0, 0.3),
    6px 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  .description {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.9);
    margin: 10px 0;

    text-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.5),
    4px 4px 6px rgba(0, 0, 0, 0.3),
    6px 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  .visit-link {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 12px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    border: 1px solid #61dafb;
    border-radius: 5px;
    transition: background 0.3s ease-in-out;
  }
  
  .visit-link:hover {
    background: #61dafb;
    color: black;
  }
  
/* Pulsing animation for the top marker */
@keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);  /* Increase the size of the marker */
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Apply this animation and glow effect only to the first timeline marker */
  .timeline-item:first-child .timeline-marker {
    animation: pulse 2s infinite ease-in-out; /* Pulse animation with 2s duration */
    box-shadow: 0 0 20px 5px rgba(97, 218, 251, 0.8); /* Stronger glowing effect */
    background-color: #61dafb; /* Glow color */
  }

  /* Hover effect for all timeline markers */
.timeline-marker:hover {
    box-shadow: 0 0 20px 5px rgba(97, 218, 251, 0.8); /* Stronger glow on hover */
    background-color: #61dafb;
    cursor: pointer;
  }