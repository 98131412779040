.skills-container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .skills-track {
    display: flex;
    animation: scroll 16s linear infinite;
  }
  
  .skill-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 12px;
    padding: 20px;
    width: 180px;
    min-width: 180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
  }
  
  .skill-icon {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  }
  
  .skill-name {
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    margin: 0 0 5px;

    text-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.5),
    4px 4px 6px rgba(0, 0, 0, 0.3),
    6px 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  .skill-desc {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.7);
    margin: 0;
    line-height: 1.4;
    white-space: normal; /* Allow text wrapping */

    text-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.5),
    4px 4px 6px rgba(0, 0, 0, 0.3),
    6px 6px 8px rgba(0, 0, 0, 0.2);
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }