.project-showcase {
  padding: 50px 20px;
  text-align: center;
  color: white;
  overflow: hidden;
}

.projects-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

/* Hover effect */
.hologram-card:hover {
  transform: perspective(1000px) rotateX(0deg) scale(1.05);
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.6);
}

/* Hologram Card (Project Showcase) */
.hologram-card {
  position: relative; /* Ensure scanline positioning */
  width: 500px; /* Increase card width */
  height: 550px; /* Increase card height */
  padding: 20px;
  background: rgba(0, 255, 255, 0.1);
  border: 2px solid rgba(0, 255, 255, 0.5);
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  transform: perspective(1000px) rotateX(10deg);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  overflow: hidden;
  text-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.5),
    4px 4px 6px rgba(0, 0, 0, 0.3),
    6px 6px 8px rgba(0, 0, 0, 0.2);
  cursor: default; /* Default cursor */

  text-align: left;
}

/* Scanline Effect - Matches About Section */
.hologram-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    to bottom,
    rgba(0, 255, 255, 0.1) 0%,  /* Faint cyan glow */
    rgba(0, 0, 0, 0.25) 50%,  /* Darker band */
    rgba(0, 255, 255, 0.1) 100% /* Faint cyan glow */
  );
  background-size: 100% 10px; /* 🔥 Matches the About scanline thickness */
  pointer-events: none;
  z-index: 2;
  opacity: 0.5; /* 🔥 Matches About section */
  animation: scanlineMove 6s linear infinite;
}

/* Scanline Movement - Keep it consistent */
@keyframes scanlineMove {
  0% { background-position: 0 -100%; }
  100% { background-position: 0 100%; }
}
/* Project Image inside the card */
.project-image {
  width: 100%; /* Make sure the image takes up the entire width */
  height: 100%; /* Ensure the image takes up the entire height of the card */
  object-fit: cover; /* Ensures the image covers the area without distortion */
  border-radius: 8px; /* Optional, for rounded corners */
  position: relative; /* For positioning scanlines */
}

/* Project Links Styling */
.project-links {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}

.project-links a {
  text-decoration: none;
  color: #00f0ff;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: color 0.3s ease-in-out;
}

.project-links a:hover {
  color: #ff00ff;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.tag {
  background: rgba(0, 255, 255, 0.2);
  color: #00f0ff;
  padding: 5px 12px;
  border-radius: 25px;
  font-size: 0.9rem;
  font-weight: 500;
  box-shadow: 0 3px 6px rgba(0, 255, 255, 0.3);
  transition: background 0.3s ease-in-out;
}

.tag:hover {
  background: rgba(0, 255, 255, 0.4);
}

.work-in-progress {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  color: #ffaa00;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(255, 170, 0, 0.6);
}