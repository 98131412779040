.app-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.three-canvas {
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
}

.text-overlay {
  text-align: center;
  text-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.5),
    4px 4px 6px rgba(0, 0, 0, 0.3),
    6px 6px 8px rgba(0, 0, 0, 0.2);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-text {
  font-size: 2em;
  color: white;
  white-space: nowrap;
}

.section-inner {
  text-align: center;
  padding: 20px;
}

.spacer {
  height: 50px;
}

body {
  background-color: #ffffff; /* or any other color */
  color: white;
}

/* Reset margin and padding for html and body */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures that padding and borders don't affect the layout */
}

html, body {
  height: 100%;
}

body {
  background-color: #000000; /* or any other color */
  color: white;
}



.skills-canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  pointer-events: none; /* Prevent blocking scrolling */
  z-index: 10; /* Ensure it’s above other elements */
}

/* Ensure content and containers fit within the screen */
.app-container, .content-container {
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden; /* Make sure nothing overflows horizontally */
}


/* Style for the navigation bar */
.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85); /* Slightly more opaque */
  color: white;
  text-align: center;
  padding: 16px 0; /* Adjusted for better spacing */
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  
  /* Flexbox for proper alignment */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px; /* Even spacing between items */
}

/* Navbar links & buttons */
.navbar a,
.navbar-button {
  font-size: 1.3rem; /* Larger text */
  font-weight: 600; /* Make it more readable */
  color: white;
  text-decoration: none;
  padding: 14px 24px; /* Increased padding for better proportions */
  display: inline-block;
  border-radius: 6px; /* Slightly rounded edges */
  transition: background 0.3s ease, transform 0.2s ease;
}

.navbar a:hover,
.navbar-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.05); /* Slight pop effect */
}

/* Social Icons */
.navbar .social-icon {
  width: 30px; /* Increase size */
  height: 30px;
  filter: brightness(0) invert(1); /* Match navbar color */
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.navbar .social-icon:hover {
  transform: scale(1.2);
  opacity: 0.8;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    gap: 15px; /* Reduce spacing on smaller screens */
  }

  .navbar a,
  .navbar-button {
    font-size: 1.2rem;
    padding: 12px 20px;
  }

  .navbar .social-icon {
    width: 26px;
    height: 26px;
  }
}
