.typewriter-container {
  display: inline-block;
  position: relative;
  white-space: nowrap;  /* Prevents text wrapping */
}

/* Default font size for desktop */
.typewriter-container {
  font-size: 100px; /* Set default font size for large screens */
}

/* Small Screens (Tablets and below) */
@media (max-width: 1024px) {
  .typewriter-container {
    font-size: 70px; /* Slightly smaller font size for tablets */
  }
}

/* Mobile Devices */
@media (max-width: 768px) {
  .typewriter-container {
    font-size: 50px; /* Even smaller font size for mobile */
  }
}

/* Very Small Screens (Narrow Phones) */
@media (max-width: 480px) {
  .typewriter-container {
    font-size: 40px; /* Minimum size for narrow phones */
  }
}

/* Typewriter Text */
.typewriter-text {
  white-space: pre-wrap;  /* Allow text to wrap if necessary */
}

/* Cursor Styling */
.cursor {
  display: inline-block;
  margin-left: 2px;
  width: 3px;
  background-color: currentColor;
  color: inherit;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
