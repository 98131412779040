.contact-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #fff;
    padding: 50px 20px; /* Ensure proper padding */
  }
  
  .contact-form {
    width: 100%;
    max-width: 600px; /* Ensures form fields don’t become too wide */
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  input, textarea {
    width: 100%; /* Makes input fields stretch across the form */
    padding: 10px;
    font-size: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    outline: none;
    box-sizing: border-box;
  }
  
  textarea {
    height: 120px;
    resize: none;
  }
  
  .submit-button {
    width: 100%; /* Makes the button match the width of inputs */
    padding: 12px;
    font-size: 1.1rem;
    background: #61dafb;
    border: none;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .submit-button:hover {
    background: #4ec3e0;
  }
  