.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #fff;
  padding: 40px 20px;
  width: 100%;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
  gap: 30px;

  text-shadow:
  2px 2px 4px rgba(0, 0, 0, 0.5),
  4px 4px 6px rgba(0, 0, 0, 0.3),
  6px 6px 8px rgba(0, 0, 0, 0.2);
}

/* Scanline Effect */
.about-hologram-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    to bottom,
    rgba(0, 255, 255, 0.1) 0%,
    rgba(0, 0, 0, 0.25) 50%,
    rgba(0, 255, 255, 0.1) 100%
  );
  background-size: 100% 8px;
  opacity: 0.6;
  pointer-events: none;
  z-index: 1;
  animation: scanlineMove 6s linear infinite;
}

/* Scanline Movement */
@keyframes scanlineMove {
  0% { background-position: 0 -100%; }
  100% { background-position: 0 100%; }
}

/* Hologram Card */
/* .about-hologram-card {
  flex: 1;
  max-width: 400px;
  padding: 20px;
  background: rgba(0, 255, 255, 0.1);
  border: 2px solid rgba(0, 255, 255, 0.5);
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.about-hologram-card:hover {
  transform: perspective(1000px) rotateX(0deg) scale(1.05);
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.6);
} */

/* .about-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  position: relative;
  z-index: 2;
} */

.about-hologram-card {
  flex: 0 0 400px; /* Adjusts size while keeping it proportional */
  width: 400px;
  height: 400px;
  background: rgba(0, 255, 255, 0.1);
  border: 2px solid rgba(0, 255, 255, 0.5);
  border-radius: 50%; /* Ensures it's a perfect circle */
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.5);
  backdrop-filter: blur(12px);
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.about-hologram-card:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
  box-shadow: 0 0 40px rgba(0, 255, 255, 0.7);
}

/* Ensure images inside are also circular */
.about-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* About Summary */
.about-summary {
  flex: 2;
  max-width: 700px;
  font-size: 1.4rem;
  color: #fff;
  line-height: 1.6;
}

.about-list {
  width: 90%;
  font-size: 1.2rem;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .about-content {
    flex-direction: column;
    align-items: center;
    text-align: left;
  }

  .about-hologram-card {
    max-width: 300px;
  }

  .about-summary {
    max-width: 90%;
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    align-items: center;
    text-align: left;
  }

  .about-hologram-card {
    width: 90%;
    max-width: 250px;
    margin-bottom: 20px;
  }

  .about-summary {
    width: 90%;
    font-size: 1.2rem;
  }
}