.placeholder {
    height: 500px;  /* Adjust as needed */
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #fff;

    font-size: 5rem; /* Increased font size */
    font-weight: bold;
    color: #fff;
    text-transform: uppercase; /* Optional: Make the title uppercase */
    letter-spacing: 2px; /* Optional: Add letter spacing */

    text-align: center;
    text-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.5),
    4px 4px 6px rgba(0, 0, 0, 0.3),
    6px 6px 8px rgba(0, 0, 0, 0.2);
  }

  /* .content-container {
    width: 100%;
  }
  
  .content-section {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    color: #e0e0e0;
  } */

  /* The issue was that font-size: 5rem is too large for smaller screens, causing the "Experience" title to overflow off-screen.
  The solution is to adjust the title's size dynamically using media queries so it scales well on all screen sizes. */

  .section-title {
    text-align: center;
    text-shadow:
      2px 2px 4px rgba(0, 0, 0, 0.5),
      4px 4px 6px rgba(0, 0, 0, 0.3),
      6px 6px 8px rgba(0, 0, 0, 2);
    
    font-size: 5rem; /* Default for desktop */
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px; /* Adds spacing to prevent overlap */
    word-wrap: break-word; /* Prevents breaking words awkwardly */
  }
  
  /* ✅ Medium Screens (Tablets) */
  @media (max-width: 1024px) {
    .section-title {
      font-size: 4rem;
    }
  }
  
  /* ✅ Small Screens (Mobile) */
  @media (max-width: 768px) {
    .section-title {
      font-size: 3rem; /* Reduce size on smaller screens */
    }
  }
  
  /* ✅ Very Small Screens (Narrow Phones) */
  @media (max-width: 480px) {
    .section-title {
      font-size: 2.5rem; /* Ensures it always fits */
    }
  }

  .content-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(3px);
  }