.footer {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 20px 0;
    position: relative;
    bottom: 0;
    z-index: 1000;  /* Ensures the navbar stays on top of other content */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);  /* Optional shadow for better visibility */
  }
  
  .footer-links {
    margin-top: 10px;
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
    margin: 0 15px;

    font-size: 2rem; /* Adjust size as needed */
    transition: color 0.3s ease-in-out;
  }
  
  .footer-links a:hover {
    color: #61DAFB; /* Light blue hover effect */
  }
  